/* eslint-disable multiline-ternary */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

export interface MessageModalInterface {
  title: string;
  description: string;
  buttonText: string;
  type: 'success' | 'error';
}

interface ConfirmationModalPropsInterface {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  message: MessageModalInterface;
}

export default function ConfirmationModal({ isOpen, setIsOpen, message }: ConfirmationModalPropsInterface) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={
                    message.type === 'success'
                      ? 'border-2 border-green-600 w-full max-w-md transform overflow-hidden rounded-2xl bg-slate-50 p-6 text-left align-middle shadow-xl transition-all '
                      : 'border-2 border-red-600 w-full max-w-md transform overflow-hidden rounded-2xl bg-slate-50 p-6 text-left align-middle shadow-xl transition-all '
                  }
                >
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {message.title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{message.description}</p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className={
                        message.type === 'success'
                          ? 'text-green-900 bg-green-100 hover:bg-green-200 inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                          : 'text-red-900 bg-red-100 hover:bg-red-200 inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                      }
                      onClick={closeModal}
                    >
                      {message.buttonText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
