import { Cell } from './cell.component';
import { useAppSelector } from '../store/hooks';
import { FoodMenuSectionEnum } from '../objects/food-menu-section.enum';
import { IsoToDayWithName } from '../utils/dateManipulation.utils';
import { CellCol } from './cell-col.component';

export function Cardapio({ menu, data, mealIndex }) {
  return (
    <div className="w-fit h-fit flex justify-center items-center bg-slate-200 border-2 border-teal-300 rounded-2xl pl-14 pt-14">
      <div className="flex flex-row space-x-1">
        <div className="w-full flex flex-col space-y-1">
          {menu?.[0]?.data?.[mealIndex]?.meal}
          <div className="w-fit h-20 text-2xl uppercase text-black font-bold"></div>
          {Object.values(FoodMenuSectionEnum).map((section, index) => (
            <div className="w-fit h-28 text-2xl uppercase text-gray-500 font-bold flex items-center " key={index}>
              {section}
            </div>
          ))}
        </div>
        {menu.map((givenDateObj, index) => (
          <div className="w-40 flex flex-col items-center justify-center" key={givenDateObj.date}>
            <div className="w-fit text-3xl uppercase text-black font-bold ">
              {IsoToDayWithName(givenDateObj.date).day}
            </div>
            <div className="w-fit mb-5 text-lg uppercase text-gray-500 font-bold ">
              {IsoToDayWithName(givenDateObj.date).name}
            </div>
            <CellCol data={data} dateIndex={index} mealIndex={mealIndex} />
          </div>
        ))}
      </div>
    </div>
  );
}
