import { useEffect, useState } from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { ComponentsFormObjectInterface, FoodComponentsInterface } from './crud-form-component';

interface FoodComponentsComponentProps {
  id: number;
  key: number;
  onDelete: (id: number) => void;
  value: ComponentsFormObjectInterface[];
  onChangeValue: (newState: any[]) => void;
}

export const FoodComponentsComponent = (props: FoodComponentsComponentProps) => {
  const metrics = [
    { id: '1', text: 'g' },
    { id: '2', text: 'mg' },
    { id: '3', text: 'un' },
    { id: '4', text: 'l' },
    { id: '5', text: 'ml' },
  ];

  const [inputObj, setInputObj] = useState({
    portionMetric: metrics[0].text,
    quantityMetric: metrics[0].text,
  } as FoodComponentsInterface);

  useEffect(() => {
    const tempObj = { id: props.id, ...inputObj };
    const i = props.value.findIndex(v => {
      return v.id === props.id;
    });
    if (i !== -1) {
      props.onChangeValue([...props.value.slice(0, i), tempObj, ...props.value.slice(i + 1)]);
    }
  }, [inputObj]);

  const handleInputChange = (event: { target: any }) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputObj({
      ...inputObj,
      [name]: value,
    });
  };

  return (
    <div className="w-fit h-fit  border-teal-200 border-2 rounded-md px-7 py-2">
      <div className="flex w-full justify-end">
        <button
          className="w-7 flex justify-end"
          onClick={() => {
            props.onDelete(props.id);
          }}
        >
          <RiDeleteBin2Line color="red" size={15} />
        </button>
      </div>
      <form className="flex flex-col">
        <label className="basic-form-label">Nome:</label>
        <input
          className="basic-form-input"
          name="name"
          placeholder="Nome do componente"
          type="text"
          value={inputObj.name}
          onChange={handleInputChange}
        />

        <div>
          <label className="basic-form-label">Quantidade:</label>
          <div className="flex space-x-2">
            <input
              className="basic-form-input"
              name="quantity"
              placeholder="Quantidade"
              type="number"
              value={inputObj.quantity}
              onChange={handleInputChange}
            />
            <select
              className="basic-form-select"
              name="quantityMetric"
              value={inputObj.quantityMetric}
              onChange={handleInputChange}
            >
              {metrics.map(metric => (
                <option key={metric.id} value={metric.text}>
                  {metric.text}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label className="basic-form-label">Porção:</label>
          <div className="flex space-x-2">
            <input
              className="basic-form-input"
              name="portion"
              placeholder="Porcionamento"
              type="number"
              value={inputObj.portion}
              onChange={handleInputChange}
            />

            <select
              className="basic-form-select"
              name="portionMetric"
              value={inputObj.portionMetric}
              onChange={handleInputChange}
            >
              {metrics.map(metric => (
                <option key={metric.id} value={metric.text}>
                  {metric.text}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>
    </div>
  );
};
