import React from 'react';
import ReactPDF, { Page, Text, Image, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { MenuState } from '../../objects/menu';
import { FoodMenuSectionEnum } from '../../objects/food-menu-section.enum';
import RULogo from '../../imgs/RU-UFRJ-Logo.jpg';
import UFRJLogo from '../../imgs/ufrj-logo-transparente.png';

const DIAS_DA_SEMANA = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

const CabecalhoDiasDaSemana = ({ arrayDiasDaSemana }) => {
  return arrayDiasDaSemana.map(diaDaSemana => (
    <View key={diaDaSemana} style={styles.tableCellHeader}>
      <Text>{diaDaSemana}</Text>
    </View>
  ));
};

const LinhasDasPreparacoes = ({ preparacaoKeys, dadosPreparacao }) => {
  return (
    <>
      {preparacaoKeys.map((preparacaoKey, index) => (
        <PlanilhaAlimentarLinha key={preparacaoKey} rowName={preparacaoKey} rowData={dadosPreparacao[preparacaoKey]} />
      ))}
    </>
  );
};

interface PlanilhaAlimentarProps {
  menuData: MenuState;
  tipoRefeicao: 'almoco' | 'janta';
}

interface PlanilhaAlimentarLinhaProps {
  rowName: string;
  rowData: string[];
}

const PlanilhaAlimentar = ({ menuData, tipoRefeicao }: PlanilhaAlimentarProps) => {
  const preparacao = {};

  const mealIndex = menuData[0].data.findIndex(item => item.meal === tipoRefeicao);

  for (let i = 0; i < menuData[i].data[mealIndex].preparations.length; i++) {
    preparacao[menuData[0].data[mealIndex].preparations[i].name] = [];
    for (let j = 0; j < menuData.length; j++) {
      preparacao[menuData[0].data[mealIndex].preparations[i].name].push(
        menuData[j].data[mealIndex].preparations[i].value,
      );
    }
  }

  const preparacaoKeys = Object.keys(preparacao);

  return <LinhasDasPreparacoes preparacaoKeys={preparacaoKeys} dadosPreparacao={preparacao} />;
};

const PlanilhaAlimentarLinha = ({ rowName, rowData }: PlanilhaAlimentarLinhaProps) => {
  const linhasComCelulasPequenas = [FoodMenuSectionEnum.ACOMPANHAMENTO as string, FoodMenuSectionEnum.SOBREMESA];

  return (
    <>
      <View style={linhasComCelulasPequenas.includes(rowName) ? styles.smallTableRow : styles.tableRow}>
        <View style={linhasComCelulasPequenas.includes(rowName) ? styles.smallTableCellName : styles.tableCellName}>
          <Text>{rowName}</Text>
        </View>
        {rowData.map((item, index) => (
          <View
            key={index}
            style={linhasComCelulasPequenas.includes(rowName) ? styles.smallTableCell : styles.tableCell}
          >
            <Text>{item}</Text>
          </View>
        ))}
      </View>
    </>
  );
};

const MenuPdf = ({ menuData }) => (
  <Document>
    <Page size="C1" orientation="landscape" style={styles.body}>
      <View style={{ height: '100%' }}>
        <View style={styles.table}>
          <View style={styles.tableRowMainHeader}>
            <View style={styles.tableCellLogo}>
              <Image style={styles.tableCellLogoImage} src={RULogo} />
            </View>
            <View style={styles.tableCellTitle}>
              <View style={styles.headerTitle}>
                <Text>SISTEMA DE ALIMENTAÇÃO DA UFRJ - RESTAURANTE UNIVERSITÁRIO</Text>
                <Text>CARDÁPIO 5 - MAIO 2023 - RU CENTRAL, CT, LETRAS, IFCS e PV</Text>
              </View>
            </View>
            <View style={styles.tableCellInfo}>
              <View style={styles.headerTitle}>
                <Text> CARDÁPIO MAIO 2023 Edição 1</Text>
              </View>
              <View style={styles.headerTitle}>
                <Text>
                  Elaborado pela equipe de Planejamento Dietético e aprovado pelas equipes SIA e Nutryenerge em 12/04/23
                </Text>
              </View>
            </View>
            <View style={styles.tableCellLogo}>
              <Image style={styles.tableCellLogoImage} src={UFRJLogo} />
            </View>
          </View>

          {/**Almoço */}
          <View style={styles.tableRowHeader}>
            <View style={styles.tableCellHeaderName}>
              <Text>Almoço</Text>
            </View>

            <CabecalhoDiasDaSemana arrayDiasDaSemana={DIAS_DA_SEMANA} />
          </View>

          <PlanilhaAlimentar menuData={menuData} tipoRefeicao="almoco" />

          {/**Jantar */}
          <View style={styles.tableRowHeader}>
            <View style={styles.tableCellHeaderName}>
              <Text>Jantar</Text>
            </View>

            <CabecalhoDiasDaSemana arrayDiasDaSemana={DIAS_DA_SEMANA} />
          </View>

          <PlanilhaAlimentar menuData={menuData} tipoRefeicao="janta" />
        </View>
      </View>
    </Page>
  </Document>
);

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const basicStyles: ReactPDF.Styles = {
  basicTableCell: {
    width: '13%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    padding: 1,
    margin: 1,
    fontSize: 18,
    textAlign: 'center',
    justifyContent: 'center',
    color: '#16161D',
  },
  basicTableRow: {
    flexDirection: 'row',
    height: '47%',
  },
  basicTableCellName: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    padding: 4,
    margin: 1,
    fontSize: '27px',
    justifyContent: 'center',
    textTransform: 'capitalize',
    color: '#16161D',
    paddingLeft: '16px',
    backgroundColor: '#C0D90470',
    fontWeight: 'bold',
  },
};

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    marginBottom: 10,
    padding: 1,
  },
  headerTitle: {
    width: '100%',
    flexDirection: 'column',
    fontSize: 27,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableRowMainHeader: {
    flexDirection: 'row',
    backgroundColor: '#D25623',
    height: '30%',
  },
  tableRowHeader: {
    flexDirection: 'row',
    backgroundColor: '#D25623',
    height: '15%',
  },
  tableCellHeader: {
    width: '13%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    padding: 4,
    margin: 1,
    fontSize: 27,
    textAlign: 'center',
    justifyContent: 'center',
  },
  tableCellHeaderName: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    padding: 4,
    margin: 1,
    fontSize: 27,
    textAlign: 'center',
    justifyContent: 'center',
    color: '#16161D',
    fontWeight: 'bold',
  },
  tableCell: {
    ...basicStyles.basicTableCell,
  },
  smallTableCell: {
    ...basicStyles.basicTableCell,
  },
  tableCellName: {
    ...basicStyles.basicTableCellName,
  },
  smallTableCellName: {
    ...basicStyles.basicTableCellName,
  },
  tableCellLogo: {
    width: '4%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    padding: 4,
    margin: 1,
  },
  tableCellLogoImage: {
    alignSelf: 'center',
  },
  tableCellTitle: {
    width: '60%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    padding: 4,
    margin: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 10,
    color: '#16161D',
  },
  tableCellInfo: {
    width: '32%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#16161D',
    padding: 4,
    margin: 1,
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 10,
    color: '#16161D',
  },
  tableRow: {
    ...basicStyles.basicTableRow,
  },
  smallTableRow: {
    ...basicStyles.basicTableRow,
    height: '20%',
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#FEFFF7',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  body: {
    padding: 2,
    flexGrow: 1,
    backgroundColor: '#F2F2F2',
  },
});

// Create Document Component
export const MyDocument = ({ menuData }) => {
  return <MenuPdf menuData={menuData} />;
};
