const dayToName = (day: number) => {
  switch (day) {
    case 0:
      return 'DOM';
    case 1:
      return 'SEG';
    case 2:
      return 'TER';
    case 3:
      return 'QUA';
    case 4:
      return 'QUI';
    case 5:
      return 'SEX';
    case 6:
      return 'SAB';
    default:
      return 'Unknown';
  }
};

export const IsoToDayMonth = (isoDate: string) => {
  const date = new Date(isoDate);
  let day = date.getUTCDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  return `${day}/${date.getUTCMonth() + 1}`;
};

export const IsoToDay = (isoDate: string) => {
  const date = new Date(isoDate);
  let day = date.getUTCDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  return `${day}`;
};

export const IsoToDayWithName = (isoDate: string) => {
  const date = new Date(isoDate);
  let day = date.getUTCDate().toString();
  if (day.length < 2) {
    day = `0${day}`;
  }
  console.log(isoDate, date);
  return { day, name: dayToName(date.getUTCDay()) };
};
