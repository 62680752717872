import { configureStore } from '@reduxjs/toolkit';
import cellReducer from '../features/cell-slice';

export const store = configureStore({
  reducer: {
    cell: cellReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
