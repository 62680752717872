import { Cell } from './cell.component';
import { useAppSelector } from '../store/hooks';

export function CellCol({ data, dateIndex, mealIndex }) {
  const menu = useAppSelector(state => state.cell);
  return (
    <div className="w-fit h-fit flex flex-col space-y-1">
      {menu[dateIndex].data[mealIndex].preparations.map((foodObj, index) => (
        <Cell data={data[foodObj.name]} dateIndex={dateIndex} mealIndex={mealIndex} preparationIndex={index} />
      ))}
    </div>
  );
}
