import { useRef, useState } from 'react';
import { IsoToDay, IsoToDayMonth, IsoToDayWithName } from '../utils/dateManipulation.utils';
import { CellCol } from './cell-col.component';
import { CellRow } from './cell-row.component';
import { Cell } from './cell.component';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { loadFile } from '../features/cell-slice';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { MyDocument } from './PDF/pdf-menu';
import { MenuState } from '../objects/menu';
import { FoodMenuSectionEnum } from '../objects/food-menu-section.enum';
import { DropDown } from './basics/dropdown';
import { BsFiletypePdf } from 'react-icons/bs';
import { BiSave, BiUpload } from 'react-icons/bi';
import { Cardapio } from './cardapio';
import { SelecaoDeCardapios } from './selecaoDeCardapios';
// Food options for the cell
const CELL_DATA = [
  {
    name: 'Maçã',
    value: 'maçã',
  },
  {
    name: 'Laranja',
    value: 'laranja',
  },
  {
    name: 'Banana',
    value: 'banana',
  },
  {
    name: 'Uva',
    value: 'uva',
  },
  {
    name: 'Maracujá',
    value: 'maracuja',
  },
  {
    name: 'Melancia',
    value: 'melancia',
  },
  {
    name: 'Abacate',
    value: 'abacate',
  },
];

export function Menu({ data }) {
  const [selecaoDeCardapios, setSelecaoDeCardapios] = useState(['Almoço']);
  const menu = useAppSelector(state => state.cell);
  const dispatch = useAppDispatch();
  let inputFileRef;
  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(menu)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'menu.json';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleOpenFile = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => {
      const text = e.target?.result;
      let obj = {} as MenuState;
      if (text) {
        obj = JSON.parse(text as string);
      }
      dispatch(loadFile(obj));
    };
    reader.readAsText(file);
  };

  const onBtnClickToOpenFile = () => {
    inputFileRef?.click();
  };

  const pdfDownloadButtonClick = () => {
    // find PDFDownloadLink a element and click it
    //const element = document.querySelector('a');

    // serach for element with fileName = menu.pdf
    const element = document.querySelector('a[download="menu.pdf"]');
    (element as HTMLAnchorElement)?.click();
  };

  const dropDownData = [
    { name: 'Salvar', icon: BiSave, onClick: handleDownload },
    { name: 'Carregar', icon: BiUpload, onClick: onBtnClickToOpenFile },
    { name: 'Exportar para PDF', icon: BsFiletypePdf, onClick: pdfDownloadButtonClick },
  ];

  return (
    <>
      <input ref={input => (inputFileRef = input)} type="file" onChange={handleOpenFile} hidden={true} />
      <div className=" w-full h-full flex flex-col p-6 mt-4 space-y-1 items-center">
        <div>
          <div className="space-x-2 mb-3">
            <DropDown name="Menu" options={dropDownData} />
            <SelecaoDeCardapios
              selecaoDeCardapios={selecaoDeCardapios}
              setSelecaoDeCardapios={setSelecaoDeCardapios}
              opcoes={['Almoço', 'Janta']}
            />
          </div>

          <div className="flex flex-col space-y-2">
            {selecaoDeCardapios.includes('Almoço') && <Cardapio data={data} menu={menu} mealIndex={0} />}
            {selecaoDeCardapios.includes('Janta') && <Cardapio data={data} menu={menu} mealIndex={1} />}
          </div>

          <div hidden={true}>
            <PDFDownloadLink document={<MyDocument menuData={menu} />} fileName="menu.pdf">
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </>
  );
}
