import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultHeader from '../../components/header.component';
import { Menu as MenuComponent } from '../../components/menu.component';
import { Api } from '../../services/api';
import { MyDocument } from '../../components/PDF/pdf-menu';
import { PDFViewer } from '@react-pdf/renderer';

export default function Menu() {
  const [menu, setMenu] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const getMenu = async () => {
    const resp = await Api.get('/food/menu');
    console.log(resp.data);
    setMenu(resp.data);
  };

  useEffect(() => {
    getMenu();
    setLoading(false);
  }, []);

  return (
    <DefaultHeader>
      <div className="w-full h-4/6 max-w-[1920px] align-middle flex flex-col justify-center items-center ">
        {loading ? (
          <div className="w-full h-full flex justify-center items-center">
            <div className="w-20 h-20 border-4 border-t-4 border-gray-500 rounded-full animate-spin"></div>
          </div>
        ) : (
          <MenuComponent data={menu} />
        )}
      </div>
    </DefaultHeader>
  );
}
