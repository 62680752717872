import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { updateCell } from '../features/cell-slice';

export function Cell({ data, dateIndex, mealIndex, preparationIndex }) {
  // Autocomplete text with the food options
  const [text, setText] = useState('');
  // The selected food
  const [selected, setSelected] = useState(null);
  // The list of food options
  const [options, setOptions] = useState(data);

  const [focus, setFocus] = useState(false);

  const menu = useAppSelector(state => state.cell);
  const dispatch = useAppDispatch();

  const enableFocus = e => {
    setFocus(true);
  };

  const disableFocus = e => {
    if (e?.relatedTarget?.nodeName !== 'BUTTON') {
      setFocus(false);
    }
  };

  // When the text changes, filter the options
  const onTextChange = e => {
    const text = e.target.value;
    dispatch(updateCell({ dateIndex, mealIndex, preparationIndex, value: text }));
    setOptions(data?.filter(option => option.name.toLowerCase().includes(text.toLowerCase())));
  };

  // When an option is selected, set the selected food
  const onOptionSelected = option => {
    console.log(option);
    setSelected(option);
    dispatch(updateCell({ dateIndex, mealIndex, preparationIndex, value: option.name }));
    setOptions([]);
  };

  return (
    <div className="w-40 h-28 relative" onFocus={enableFocus}>
      <textarea
        onBlur={disableFocus}
        className="w-full h-full text-center bg-slate-50 break-all resize-none pt-2"
        value={menu[dateIndex].data[mealIndex].preparations[preparationIndex].value}
        onChange={onTextChange}
      />
      {options?.length > 0 && focus && (
        <div className="w-full max-h-52 overflow-y-auto flex flex-col bg-slate-50 border-2 border-slate-200 rounded-md  absolute -top-3 -right-full z-10">
          {options.map(option => (
            <button
              className="hover:bg-slate-300 p-1 cursor-pointer text-left leading-tight"
              key={option.value}
              onClick={() => onOptionSelected(option)}
            >
              {option.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
