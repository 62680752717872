import { FoodMenuSectionEnum } from './food-menu-section.enum';
import { MenuState } from './menu';

export const INITIAL_STATE: MenuState = [
  {
    date: '2021-08-01',
    data: [
      {
        meal: 'almoco',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
      {
        meal: 'janta',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
    ],
  },
  {
    date: '2021-08-02',
    data: [
      {
        meal: 'almoco',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
      {
        meal: 'janta',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
    ],
  },
  {
    date: '2021-08-03',
    data: [
      {
        meal: 'almoco',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
      {
        meal: 'janta',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
    ],
  },
  {
    date: '2021-08-04',
    data: [
      {
        meal: 'almoco',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
      {
        meal: 'janta',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
    ],
  },
  {
    date: '2021-08-05',
    data: [
      {
        meal: 'almoco',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
      {
        meal: 'janta',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
    ],
  },
  {
    date: '2021-08-06',
    data: [
      {
        meal: 'almoco',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
      {
        meal: 'janta',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
    ],
  },
  {
    date: '2021-08-07',
    data: [
      {
        meal: 'almoco',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
      {
        meal: 'janta',
        preparations: [
          {
            name: FoodMenuSectionEnum.ENTRADA,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_PRINCIPAL,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.PRATO_VEGANO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.GUARNICAO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.ACOMPANHAMENTO,
            value: '',
          },
          {
            name: FoodMenuSectionEnum.SOBREMESA,
            value: '',
          },
        ],
      },
    ],
  },
];
