import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuState } from '../objects/menu';
import { INITIAL_STATE } from '../objects/menu-initial-state';
import { FoodMenuSectionEnum } from '../objects/food-menu-section.enum';

interface CellState {
  text: string;
}

type PreparationCell = {
  name: FoodMenuSectionEnum;
  value: string;
};

type MealInfo = {
  meal: string;
  preparations: PreparationCell[];
};

type DayInfo = {
  date: string;
  data: MealInfo[];
};

type PayloadType = {
  dateIndex: number;
  mealIndex: number;
  preparationIndex: number;
  value: string;
};

const cellSlice = createSlice({
  name: 'cell',
  initialState: INITIAL_STATE,
  reducers: {
    updateCell(state, action: PayloadAction<PayloadType>) {
      const { dateIndex, mealIndex, preparationIndex, value } = action.payload;
      state[dateIndex].data[mealIndex].preparations[preparationIndex].value = value;
    },
    loadFile(state, action: PayloadAction<MenuState>) {
      return action.payload;
    },
  },
});

export const { updateCell, loadFile } = cellSlice.actions;

export default cellSlice.reducer;
